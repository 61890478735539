@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

@font-face {
  font-family: "mona-sans";
  src: url("../fonts/MonaSans/Mona-Sans.ttf") format("truetype"),
    url("../fonts/MonaSans/Mona-Sans.woff2") format("woff2");
}

body {
  margin: 0;
  font-family: "Roboto Flex", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbf9f5;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
