/* FONTS */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "mona-sans";
  src: url("../fonts/MonaSans/Mona-Sans.ttf") format("truetype"),
    url("../fonts/MonaSans/Mona-Sans.woff2") format("woff2");
}

/* ::selection {
  background-color: #11151c;
  color: rgb(255, 255, 255);
} */

.return-button {
  cursor: pointer;
  border: none;
  background: none;
  color: #333333;
  font-size: 18px;
  text-decoration: underline;
  font-weight: bold;
}

.return-button:hover {
  color: #9d9d9d;
}

/* REGISTER */
/* DESKTOP */
@media only screen and (min-width: 1168px) {
  .name-input {
    width: 29% !important;
  }

  .mobile {
    display: none;
  }

  .split-container {
    height: 100vh;
  }

  .right-column {
    padding-top: 30vh;
    min-height: 700px;
  }
}

/* MOBILE */
@media only screen and (max-width: 1168px) {
  .login-form {
    max-width: 383px;
  }

  .split-container {
    flex-direction: column;
  }

  .left-column button {
    width: 100% !important;
  }

  .left-column svg {
    display: none;
  }

  .left-column {
    margin-top: 3% !important;
    order: 2;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    padding: 0;
  }

  .right-register-content {
    max-width: 80%;
    max-height: 10%;
  }

  .left-column input {
    max-width: 80%;
    height: 50px;
  }

  .left-column input[type="checkbox"] {
    width: 10px;
    height: 10px;
  }

  .simple-button {
    max-width: 80%;
  }

  .name-input {
    width: 100% !important;
  }

  .wide-input {
    width: 100% !important;
  }

  input {
    width: 100%;
  }

  .right-column {
    order: 1;
    padding-top: 0vh;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block !important;
  }

  .right-column p {
    display: none;
  }

  .right-column svg {
    margin-top: 3vh;
  }

  .right-column h1 {
    font-size: 30px !important;
    margin-bottom: 10%;
  }
}

.founder-card {
  width: 20vh;
  float: right;
  margin-top: 5%;
  margin-right: 15%;
}

.founders-image {
  width: 130px;
  margin-right: 10px;
}

.join-founders-text {
  font-weight: bold;
  margin: 0;
  color: #ffffff;
}

/* color: "#c931c2", textDecoration: "none" */
.link {
  color: #c931c2;
  text-decoration: none;
}

.link {
  color: #c931c2;
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  width: auto;
  box-shadow: none;
  border-radius: 0;
}

.link:hover {
  text-decoration: underline;
}

.right-register-content {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: right;
  max-width: 85%;
}

.right-column {
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: #0d0f0e;
  color: white;
}

.split-container {
  display: flex;
}

.right-register-content h1 {
  font-size: 60px;
  font-weight: bold;
}

.right-register-content p {
  font-size: 18px;
}

.left-column {
  margin: 0 auto;
  margin-top: 4%;
  margin-bottom: 5%;
  padding: 8%;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.left-column input {
  width: 60%;
  height: 60px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #8f8f8f;
  box-sizing: border-box;
  font-size: 15px;
  /* font-family: 'mona-sans', sans-serif; */
}

.left-column input[type="checkbox"] {
  width: 12px;
  height: 120px;
}

.simple-button {
  background-color: #000000;
  font-size: 22px;
  height: 60px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 60%;
  box-shadow: 2.5px 2.5px 0px 1px #c8c8c8;
  border-radius: 3px;
}

.apply-button {
  background-color: #774df0;
  font-size: 22px;
  height: 60px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  min-width: 350px;
  width: 25%;
  box-shadow: 2.5px 2.5px 0px 1px #000000;
  border-radius: 3px;
}

.save-button {
  background-color: #774df0;
  font-size: 22px;
  height: 60px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 60%;
  box-shadow: 2.5px 2.5px 0px 1px #000000;
  border-radius: 3px;
}

.simple-button:hover {
  background-color: #1c1c1c;
}

.sign-out-button:hover {
  text-decoration: underline;
}

/* HEADER */
.header {
  color: rgb(0, 0, 0);
  padding: 1.5vh 0;
  height: 5vh;
  background-color: #ffffff00;
}

.header-left {
  color: #111111;
  font-size: 11px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 95%;
  margin: 0 auto;
}

.header-left {
  flex: 1;
}

.header-center {
  display: flex;
  text-align: right;
}

.header-link {
  color: #111111;
  margin: 0 10px;
  text-decoration: none;
  font-weight: bold;
}

.header-link:hover {
  text-decoration: underline;
}

/* BANNER */
.banner {
  background-color: #1c1c1c;
  padding: 0.01vh 0;
  color: white;
  text-align: center;
}

.banner a {
  color: white;
}

.banner a:hover {
  color: rgb(189, 189, 189);
}

/* APPLICATION */
.application-page {
  margin: 3% 5% 5% 7%;
}

.application-page input {
  width: 350px;
  max-width: 80%;
}

.application-page textarea {
  min-width: 350px;
  width: 60%;
  height: 150px;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}

.application-page p {
  max-width: 60%;
}

.page-container {
  margin: 5% 12% 0% 12%;
}

.loading {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100vh;
}

/* HOME */
.FAQ {
  margin-top: 10vh;
  background-color: white;
  width: 80%;
  height: 30%;
  padding: 43px;
  border-radius: 4px;
  box-shadow: 2.5px 2.5px 0px 1px #000000;
  margin-bottom: 15%;
  outline: black 1px solid;
}

.subtext {
  color: #666666;
}

/* .application-status {
  display: flex;
  width: 87%;
}

.left-col {
  flex: 1;
}

.right-col {
  flex: 2;
  display: flex;
  justify-content: right;
  align-items: center;
} */

/* FOOTER */
.footer {
  color: #1c1c1c;
  padding: 1.5vh 0;
  text-align: center;
}
